<template>
  <div>
    <CCard class="p-0 m-0 form-card">
      <CCardHeader>
        <label class="header-title">警報情報</label>
      </CCardHeader>
    </CCard>
    <CCard class="p-0 m-0 grid-card">
      <CCardBody class="p-0 my-auto">
        <CRow>
          <CCol col="3">
            <CButtonGroup>
              <CButton :color="endFlg ? 'secondary':'info' " @click="changeEndFlg(false)">発生中の警報</CButton>
              <CButton :color="endFlg ? 'info':'secondary'" @click="changeEndFlg(true)">過去の警報</CButton>
            </CButtonGroup>
          </CCol>
          <CCol col="9" class="text-right">
            <CButtonGroup class="mr-2">
              <CButton :color="periodDays == 1    ? 'info':'secondary' " @click="changeDays(1)">24時間以内</CButton>
              <CButton :color="periodDays == 7    ? 'info':'secondary'"  @click="changeDays(7)">1週間以内</CButton>
              <CButton :color="periodDays == 30   ? 'info':'secondary'"  @click="changeDays(30)">1ヶ月以内</CButton>
              <CButton :color="periodDays == 90   ? 'info':'secondary'"  @click="changeDays(90)">３ヶ月以内</CButton>
              <CButton :color="periodDays == null ? 'info':'secondary'"  @click="changeDays(null)">すべて</CButton>
            </CButtonGroup>


<!--              <input id="day1" type="radio" v-model="periodDays" value="1" class="ml-5">-->
<!--              <label for="day1">24時間以内</label>-->

<!--              <input id="day7" class="ml-4" type="radio" v-model="periodDays" value="7" style="vertical-align: middle;">-->
<!--              <label for="day7">1週間以内</label>-->

<!--              <input id="day30" class="ml-4" type="radio" v-model="periodDays" value="30">-->
<!--              <label for="day30">1ヶ月以内</label>-->

<!--              <input id="day90" class="ml-4" type="radio" v-model="periodDays" value="90">-->
<!--              <label for="day90">３ヶ月以内</label>-->

<!--              <input id="dayAll" class="ml-4" type="radio" v-model="periodDays" value="">-->
<!--              <label for="dayAll" class="mr-4">すべて</label>-->
          </CCol>
        </CRow>



        <MyBeeSearchBar @goodPaging="goodPaging" @changePage="changePage" @devSearch="devSearch"
                        :curr-page.sync="pagingParams.currPage"
                        :per-page.sync="pagingParams.perPage"
                        :per-page-list="perPageList"
                        :pages="pages"
                        :search-bar-place-holder="searchBarPlaceHolder"
                        :keyword.sync="pagingParams.keyword">
          <template slot="tools">
            <CButton v-if="isProvider()" size="sm" color="warning mr-1"  variant="outline" :pressed="allBranchFlg" @click="toggleAllBranch">全事業所</CButton>
          </template>
        </MyBeeSearchBar>

        <BeeGridTable
            ref="bee"
            border
            stripe
            :showFilter="false"
            :columns="columns"
            :loading="isLoading"
            :data="rows"
            :showPager="false"
            :maxHeight="gridCardHeight"
            highlightRow
            no-data-text="データがありません。"
            no-filtered-data-text=""
            :context="context"
            :span-method="customSpan"
            :showVerticalScrollBar="false"
            :showHorizontalScrollBar="false">
          <!-- HEADER ソート可能にするために 必要-->
          <template slot-scope="{ column,index }" slot="startDateHead"><MyBeeHeader :column="column" :index="index" @sort="sort"></MyBeeHeader></template>
          <template slot-scope="{ column,index }" slot="endDateHead"><MyBeeHeader :column="column" :index="index" @sort="sort"></MyBeeHeader></template>
          <template slot-scope="{ column,index }" slot="deviceKindHead"><MyBeeHeader :column="column" :index="index" @sort="sort"></MyBeeHeader></template>
          <template slot-scope="{ column,index }" slot="modelNumberHead"><MyBeeHeader :column="column" :index="index" @sort="sort"></MyBeeHeader></template>
          <template slot-scope="{ column,index }" slot="serialNumberHead"><MyBeeHeader :column="column" :index="index" @sort="sort"></MyBeeHeader></template>
          <template slot-scope="{ column,index }" slot="warningCodeHead"><MyBeeHeader :column="column" :index="index" @sort="sort"></MyBeeHeader></template>
          <template slot-scope="{ column,index }" slot="warningLabelHead"><MyBeeHeader :column="column" :index="index" @sort="sort"></MyBeeHeader></template>
          <template slot-scope="{ column,index }" slot="agencyNameHead"><MyBeeHeader :column="column" :index="index" @sort="sort"></MyBeeHeader></template>
          <template slot-scope="{ column,index }" slot="agencyBranchNameHead"><MyBeeHeader :column="column" :index="index" @sort="sort"></MyBeeHeader></template>
          <template slot-scope="{ column,index }" slot="tantoushaHead"><MyBeeHeader :column="column" :index="index" @sort="sort"></MyBeeHeader></template>



          <template slot-scope="{ row,index }" slot="endDate">
            <div  class="text-left">
              <span v-if="index % 2 == 0">{{row.startDate}}</span>
              <span v-else>{{row.endDate}}</span>
            </div>
          </template>

          <template slot-scope="{ row,index }" slot="modelNumber">
            <div v-if="index % 2 == 0" class="text-left">{{ row.deviceKind }}</div>
            <div v-else class="text-left">{{ row.modelNumber }}</div>
          </template>


<!--          <template slot-scope="{ row,index }"  slot="modelNumber"><div>{{row.modelNumber}}</div></template>-->
          <template slot-scope="{ row,index }"  slot="serialNumber"><div class="text-left">{{row.serialNumber}}</div></template>
<!--          <template slot-scope="{ row,index }"  slot="warningCode"><div>{{row.warningCode}}</div></template>-->
          <template slot-scope="{ row,index }" slot="warningLabel">
            <div v-if="index % 2 == 0" class="text-center point" >
              <span>{{ row.warningCode }}</span>
            </div>
            <div v-else class="text-center point">
              <span>{{ row.warningLabel }}</span>
            </div>
          </template>
<!--          <template slot-scope="{ row,index }"  slot="agencyName"><div>{{row.agencyName}}</div></template>-->
          <template slot-scope="{ row,index }"  slot="agencyBranchName">
            <div v-if="index % 2 == 0" class="text-left">{{ row.agencyName }}</div>
            <div v-else slot="agencyBranchName" class="text-left">{{ row.agencyBranchName }}</div>
          </template>
          <template slot-scope="{ row,index }"  slot="tel">
            <div v-if="index % 2 == 0" slot="agencyBranchName" class="text-left">{{ row.tantousha }}</div>
            <div v-else slot="agencyBranchName" class="text-left">{{ row.tel }}</div>
          </template>
        </BeeGridTable>
      </CCardBody>
    </CCard>
  </div>
</template>

<script>
import BeeMix from '@/views/aprod/js/mixin/beeUtils'
import MyBeeHeader from "@/component/parts/MyBeeHeader";
import MyBeeSearchBar from "@/component/parts/MyBeeSearchBar";
export default {
  name: 'DeviceHealthInfo',
  components: {
    MyBeeHeader, MyBeeSearchBar
  },
  mixins: [BeeMix],
  data() {
    return{
      // pagingParams: {keyword: '', columnFilters: {}, sort: [], currPage: 1, perPage: 25},
      pagingParams: Object.assign(BeeMix.data().pagingParams,{sort: [{field: "startDate", sort: "desc"}]}),
      columns: [
        //{field: 'no',label: 'No',  sortable: false, width: "70px"},
        {key: 'startDate', title: '発生日時',headSlot:"startDateHead",align:'center'
           ,children: [{key: 'endDate', title: '終了日時',slot:"endDate",headSlot:"endDateHead",align:'center'}]
        },

        {key: 'deviceKind', title: '機種区分',slot:"deviceKind",headSlot:"deviceKindHead",align:'center',
            children: [{key: 'modelNumber', title: '機種',slot:"modelNumber",headSlot:"modelNumberHead",align:'center'}]
        },

        {key: 'serialNumber', title: 'シリアル番号',slot:"serialNumber",headSlot:"serialNumberHead",align:'center'},
        {key: 'warningCode', title: '警報コード',slot:"warningCode",headSlot:"warningCodeHead",align:'center',
          children: [{key: 'warningLabel', title: '警報種類',slot:"warningLabel",headSlot:"warningLabelHead",align:'center'}]
        },

        {key: 'agencyName', title: '販売店',slot:"agencyName",headSlot:"agencyNameHead",align:'center',
          children: [{key: 'agencyBranchName', title: '事業所',slot:"agencyBranchName",headSlot:"agencyBranchNameHead",align:'center'}]},
        {key: 'tantousha', title: '担当者',slot:"tantousha",headSlot:"tantoushaHead",align:'center',
        children: [{key: 'tel', title: '連絡先',slot:"tel",align:'center'}]},

      ],
      periodDays:null,
      endFlg:false,
      searchBarPlaceHolder:"機種区分、機種、警報種類、販売店、事業所で検索",
      allBranchFlg:false
    }
  },
  // watch:{
  //   periodDays(){
  //     this.goodPaging();
  //   }
  // },
  methods: {
    changeEndFlg:function(flg){
      let isChange = this.endFlg != flg;
      this.endFlg = flg;
      if(this.endFlg == true){
        this.periodDays = 1;
      }else{
        this.periodDays = null;
      }
      if(isChange){
        this.pagingParams.sort = [{field: "startDate", sort: "desc"}];
        this.goodPaging();
      }
    },changeDays:function(days){
      let isChange = this.periodDays != days;
      this.periodDays = days;
      if(isChange){
        this.goodPaging();
      }
    },
    customSpan({ row, column, rowIndex, columnIndex }){
      if(rowIndex % 2 == 1) {
        // if (["startDate"].includes(column.key)) {
        //   return {rowspan: 2, colspan: 1};
        // }
        if (["deviceKind"].includes(column.key)) {
          return {rowspan: 2, colspan: 1};
        }
        if (["warningCode"].includes(column.key)) {
          return {rowspan: 2, colspan: 1};
        }
        if (["agencyName"].includes(column.key)) {
          return {rowspan: 2, colspan: 1};
        }
        if (["tantousha"].includes(column.key)) {
          return {rowspan: 2, colspan: 1};
        }
      }

      if(["modelNumber","warningCode","warningLabel","agencyName","agencyBranchName"
      ,"tantousha","tel","startDate","endDate"].includes(column.key)){
        return {rowspan: 1,colspan: 1};
      }

      if(rowIndex % 2 == 0){
        return {
          rowspan: 2,colspan: 1
        };
      }else{
        return {
          rowspan: 2,colspan: 0
        };
      }
    },
    goodPaging(isPageChange) {
      if (!isPageChange) this.pagingParams.currPage = 1;//ページが以外が変わったら最初から検索する
      this.isLoading = true;

      if(this.periodDays == ""){
        this.periodDays = null;
      }

      this.axios.post('/api/device/warningPaging',
          {pagingForm:this.pagingParams,
            periodDays:this.periodDays,
            endFlg:this.endFlg,
            allBranchFlg:this.allBranchFlg
          }, {headers: {'Content-Type': 'application/json'}}).then(response => {
        this.totalRecords = response.data.result.totalElements;
        let list=[];
        for(let i=0; i<response.data.result.content.length; i++){
          list.push(Object.assign({indexKey:Math.random().toString(32).substring(2)+i},response.data.result.content[i]));
          list.push(Object.assign({indexKey:Math.random().toString(32).substring(2)+i},response.data.result.content[i]));
        }
        this.rows = list;

        //ページ件数
        this.pages = response.data.result.totalPages;
        this.topLeft();
      }).catch(error => console.log(error)
      ).finally(() => {
        this.isLoading = false;
      });
    }, csvDownload() {
      this.isLoading = true;

      console.log(
          JSON.stringify({pagingForm:this.pagingParams,
            periodDays:this.periodDays,
            endFlg:this.endFlg
          })
      )
      this.axios.post('/api/device/warningPaging',
          {pagingForm:this.pagingParams,
            periodDays:this.periodDays,
            endFlg:this.endFlg
          }
          , {headers: {'Content-Type': 'application/json'}, responseType: 'blob'}).then(
          response => {
            let url = window.URL.createObjectURL(new Blob([response.data]));
            let link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', decodeURI(response.headers.filename));
            document.body.appendChild(link);
            link.click();

          }).catch(error => console.log(error)
      ).finally(() => this.isLoading = false);
    },toggleAllBranch(){
      this.allBranchFlg = !this.allBranchFlg;
      this.goodPaging()
    }
  }
}
</script>
<style scoped>
.point span{
  font-size: 17px;
  font-weight: bold;
  color:red;
}

</style>
